<template>
  <!-- 已报名竞价 -->
  <div class="subPage" id="winningBidding">
    <el-col :span="24" class="selectHeadBox">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="竞拍号">
          <template slot-scope="scope">{{ scope.row.orderId }}</template>
        </el-table-column>
        <el-table-column label="竞价类型">
          <template slot-scope="scope">{{
            scope.row.type | typeFormate
          }}</template>
        </el-table-column>
        <el-table-column prop="commodity" label="商品分类"> </el-table-column>
        <el-table-column prop="weight" label="重量" min-width="60">
        </el-table-column>
        <el-table-column prop="quantity" label="数量" min-width="60">
        </el-table-column>
        <el-table-column prop="startPrice" label="起拍总价(元)">
        </el-table-column>
        <el-table-column prop="storageName" label="交收仓库"> </el-table-column>
        <el-table-column label="中标价格">
          <template slot-scope="scope">{{ scope.row.price }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">{{ scope.row.style }}</template>
        </el-table-column>
        <el-table-column label="竞拍时间" width="180">
          <template slot-scope="scope">
            <p>{{ scope.row.startTime | DateFormate }} 至</p>
            <p>{{ scope.row.endTime | DateFormate }}</p>
          </template>
        </el-table-column>
        <!-- <el-table-column
            label="操作"
            width="130"
            >
            <template slot-scope="scope">
              <el-button v-if="scope.row.orderStatus=='O'"
                @click.native.prevent="deleteRow(scope.$index,scope.row)"
                type="waring"
                size="small">
                摘单确认
              </el-button>
            </template>
          </el-table-column> -->
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </div>
    </el-col>
    <!-- 竞价摘单确认 -->
    <el-dialog
      v-dialogDrag
      title="竞价摘单确认"
      :visible.sync="SignUp.isGoSignUp"
      width="540px"
    >
      <el-row>
        <el-col :span="24">请选择仓储编号</el-col>
        <el-col :span="24">
          <el-select class="width-100" v-model="warehousId" clearable>
            <el-option
              v-for="item in warehousIdOptions"
              :key="item.warehousId"
              :label="
                item.warehousId + '' + item.commodity + ' ' + item.warehousCode
              "
              :value="item.warehousId"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="24">提示:如不选择仓储编号将默认冻结履约订金</el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFrom()">取 消</el-button>
        <el-button
          type="primary"
          :disabled="isAddLoading"
          :loading="isAddLoading"
          @click="goSignUp()"
          >摘单确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      activeIndex: "1",
      selectActiveIndex: "1",
      isNewOrder: false, // 新增求购单按钮状态,
      isdeleatOrders: false, // 批量删除按钮状态
      tableData: [], // http://114.116.96.237:38808/apigateway/precacheservice/doc.html#/default/capacity-controlller/queryBiddingBuyerResourceUsingPOST
      multipleSelection: [],
      selectAllTableData: false,
      //分页
      current: 1,
      pageSize: 10,
      totalPage: null,
      categoryProps: {
        label: "Value",
        value: "Value",
        checkStrictly: false
      },
      selectAuctionWinningBid: null,
      // 仓储查询
      warehousIdOptions: [],
      warehousId: null,
      SignUp: {
        isGoSignUp: false,
        loading: false
      },
      isAddLoading: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.getDatas();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    // 资源管理-仓储查询
    getWarehousIdOptions(commodity) {
      let warehousIdList = [];
      protocolFwd.param_querySpotWarehouse.param.fStatus = [0];
      http.postFront(protocolFwd.param_querySpotWarehouse).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.length; i++) {
            if (value[i].commodity == commodity) {
              warehousIdList.push(value[i]);
            }
          }
          this.warehousIdOptions = warehousIdList;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      protocolFwd.param_queryAuctionWinningBid.param = {
        hangResourceType: ["Q"], //订单类型X：现货挂单 B：履约订金挂单Q：求购单
        page: this.current - 1,
        size: this.pageSize,
        sort: [{ property: "id", direction: "DESC" }],
        firmId: this.sessionInfoGetter.firmId,
        hangResourcesStatus: ["O", "B", "A"]
      };
      http
        .postFront(protocolFwd.param_queryAuctionWinningBid)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            let arr = [];
            let nowTime = Number(new Date());
            for (let i = 0; i < value.content.length; i++) {
              let element = value.content[i];
              let startTime = element.startTime ? Number(element.startTime) : 0;
              let endTime = element.endTime ? Number(element.endTime) : 0;
              if (nowTime < startTime) {
                element["style"] = "公示中";
              } else if (nowTime >= startTime && nowTime < endTime) {
                element["style"] = "竞拍中";
              } else if (nowTime >= endTime) {
                element["style"] = "已结束";
              }
              arr.push(element);
            }
            this.tableData = arr;
            this.totalPage = value.totalElements;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    // table 选中调用方法
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val);
    },
    // 操作
    deleteRow(index, row) {
      console.log(index, row);
      this.selectAuctionWinningBid = row;
      this.SignUp.isGoSignUp = true;
      this.getWarehousIdOptions(row.commodity);
    },
    resetFrom() {
      this.SignUp.isGoSignUp = false;
    },
    // 竞价摘单确认
    goSignUp() {
      protocolNJP.param_confirmBidOrder.param.warehousId = this.warehousId
        ? Number(this.warehousId)
        : null;
      protocolNJP.param_confirmBidOrder.param.bidOrderId = Number(
        this.selectAuctionWinningBid.id
      );
      this.isAddLoading = true;
      http
        .postNewJP(protocolNJP.param_confirmBidOrder)
        .then(response => {
          const { code, message, value } = response.data;
          this.isAddLoading = false;
          this.SignUp.isGoSignUp = false;
          if (code == 0) {
            this.dialogVisible = false;
            this.$EL_MESSAGE(message);
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(() => {
          this.SignUp.isGoSignUp = false;
          this.isAddLoading = false;
        });
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.selectHeadBox {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.headText {
  line-height: 40px;
  text-align: center;
}
.pagination {
  margin-top: 15px;
}
.selectHead {
  margin-bottom: 10px;
}
</style>
